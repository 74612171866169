import React from 'react';
import fire from './fire.png';
import './App.css';
import { motion } from "framer-motion"

function App() {
  return (
    <div className="App">
      <div style={{display:"flex",height:'100vh',alignItems:"center",justifyContent:"center"}}>
        <div style={{height:400,width:400,position:"relative",border:'1px solid #d3d3d3'}} className="maindiv">
          {[-50,0,50].map(i=><div>
            <motion.div
              style={{height:30,width:6,borderRadius:8,backgroundColor:"white",border:"2px solid black"}}
              animate={{ scale: [2,2], opacity: [0,1], y: [0,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,-82,0], x: [i,i] }}
              transition={{ duration: 8, delay: 1 }}
            />
          </div>)}
          {[-50,0,50].map(i=><div>
            <motion.img
              style={{height:12,width:12}}
              animate={{ scale: [1,4,3,3,3,3,3,0], opacity: [0,1,1,1,1,1,1,0], y: [-124,-124], x: [i,i] }}
              transition={{ duration: 2, delay: 1.5 }}
              src={fire}
            />
          </div>)}
          <div>
            <motion.div
              style={{height:60,width:100,border:"2px solid black",borderRadius:4,background:`linear-gradient(
                to bottom,
                #FFE0B2,
                #FFE0B2 40%,
                #EF5350 40%,
                #EF5350 60%,
                #FFE0B2 60%,
                #FFE0B2
              )`}}
              animate={{ scale: [0,2], opacity: [0,1], y: [60,0] }}
              transition={{ duration: 0.8 }}
            >
              <motion.div
                style={{height:0}}
                animate={{ scale: [1,1], opacity: [0,1,1,0], y: [4.5,4.5,4.5,4.5], color: 'black', lineHeight: 1 }}
                transition={{ duration: 2.3, delay: 3 }}
              >
                Dear<br/><br/>Grandma,
              </motion.div>
              <motion.div
                style={{height:0}}
                animate={{ scale: [1,1], opacity: [0,1,1,0], y: [4.5,4.5,4.5,4.5], color: 'black', lineHeight: 1 }}
                transition={{ duration: 2.3, delay: 5.3 }}
              >
                Happy<br/><br/>Birthday!
              </motion.div>
              <motion.div
                animate={{ scale: [1,1], opacity: [0,1,1,0], y: [4.5,4.5,4.5,4.5], color: 'black', lineHeight: 1 }}
                transition={{ duration: 2.3, delay: 7.6 }}
              >
                Love From<br/><br/>Sam xx
              </motion.div>
            </motion.div>
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1], y: [-20,-20], x: [75,75], filter: 'invert(0)' }}
              transition={{ duration: 0, delay: 9 }}
              src={require('./bite.png')}
            />
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1], y: [-20,-20], x: [110,110], filter: 'invert(0)' }}
              transition={{ duration: 0, delay: 9.5 }}
              src={require('./bite2.png')}
            />
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1], y: [-45,-45], x: [-115,-115], filter: 'invert(0)' }}
              transition={{ duration: 0, delay: 10 }}
              src={require('./bite2.png')}
            />
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1], y: [-20,-20], x: [-115,-115], filter: 'invert(0)' }}
              transition={{ duration: 0, delay: 10.5 }}
              src={require('./bite2.png')}
            />
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1], y: [-45,-45], x: [-95,-95], filter: 'invert(0)' }}
              transition={{ duration: 0, delay: 11 }}
              src={require('./bite2.png')}
            />
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1,1,0], filter: 'invert(0)' }}
              transition={{ duration: 2, delay: 11.5 }}
              src={'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/facebook/230/party-popper_1f389.png'}
            />
          </div>
          
          <div>
            <motion.img
              style={{height:96,width:96}}
              animate={{ opacity: [0,1], filter: 'invert(0)' }}
              transition={{ duration: 0.75, delay: 14 }}
              src={'https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/120/facebook/230/wrapped-present_1f381.png'}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
